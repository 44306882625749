<template>
  <!-- DIALOG -->
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title>Aikaisemmat {{ title }}</v-card-title>

      <!-- TABS -->
      <v-card-text>
        <v-tabs v-model="tab" color="primary" class="mb-3" fixed-tabs show-arrows>
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab v-for="item in tabs" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
      </v-card-text>

      <!-- ADD OLD -->
      <v-card-text v-show="tab == 0">
        <p class="mb-5 error--text">
          Muista tallentaa vuokrakohteen tiedot, jos lisäät tai poistat vastikkeita.
        </p>
        <v-form ref="form">
          <v-select
            v-model="charge.condominiumLoanId"
            label="Nimi"
            :items="activeCondominiumLoans"
            item-text="name"
            item-value="_id"
            no-data-text="Lisää laina ensin taloyhtiöön"
            :rules="validations.required"
            dense
            outlined
            @change="setFieldsByCurrentLoan"
          ></v-select>

          <v-text-field
            v-model.number="charge.amount"
            type="number"
            step="0.01"
            label="Maksuerä"
            :rules="validations.required.concat(validations.positiveNumber)"
            suffix="€/kk"
            outlined
            dense
          ></v-text-field>

          <v-menu ref="menu" v-model="menu" :close-on-content-click="false" min-width="290">
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="formatMonthDate(charge.endDate)"
                dense
                outlined
                v-bind="attrs"
                :rules="validations.required"
                v-on="on"
                append-icon="mdi-calendar"
                label="Päättyen"
                v-prevent-manual-input
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="charge.endDate"
              first-day-of-week="1"
              type="month"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>

          <v-btn color="primary" @click="addCharge()" small>Lisää vastike</v-btn>
        </v-form>
      </v-card-text>

      <!-- OLD LIST -->
      <v-card-text v-show="tab == 1" class="card-oldlist">
        <p class="mb-5 error--text">
          Muista tallentaa vuokrakohteen tiedot, jos lisäät tai poistat hoitovastikkeita.
        </p>

        <div v-for="(charge, idx) in currentApartment[field]" :key="idx">
          <v-row dense>
            <v-col cols="6">
              <p style="font-size: 14px">
                <strong>{{ formatDate(charge.endDate) }} asti</strong>
              </p>
              <p>{{ getLoanByCondominiumLoanId(charge)["name"] }}</p>
              <p>{{ formatHandling(getLoanByCondominiumLoanId(charge)["handling"]) }}</p>
              <p style="margin-bottom: -4px; font-size: 15px">
                {{ formatCurrency(charge.amount) }}/kk
              </p>
            </v-col>

            <v-col cols="6">
              <v-icon small @click="deleteItem(charge, idx)" color="error">mdi-delete</v-icon>
            </v-col>
          </v-row>
          <v-divider v-if="idx < currentApartment[field].length - 1" class="mt-1 mb-1"></v-divider>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" outlined @click="dialog = false"> Sulje </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "../../mixins/mixins";
import { mapState, mapMutations } from "vuex";
import moment from "moment";
import validations from "@/validations";

export default {
  mixins: [mixins],

  props: {
    value: { type: Boolean, default: false },
    title: { type: String, default: "" },
    field: { type: String, default: "" },
  },

  data() {
    return {
      menu: false,
      charge: {
        amount: null,
        endDate: "",
        condominiumLoanId: null,
      },
      tab: 0,
      tabs: ["Luo", "Lisätyt"],
      validations,
    };
  },

  computed: {
    ...mapState("apartment", ["currentApartment"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    condominiumLoans() {
      return this.currentApartment.condominium.loans;
    },

    activeCondominiumLoans() {
      return this.currentApartment.condominium.loans.filter((el) => el.active);
    },
  },

  watch: {
    value() {
      this.tab = 0;
      this.charge = {
        amount: null,
        endDate: "",
        condominiumLoanId: null,
      };
      this.$nextTick(() => {
        this.$refs.form.resetValidation();
      });
    },

    tab() {
      if (this.tab == 1) {
        let parsedDates = this.currentApartment[this.field].map((el) => {
          el.date = Date.parse(el.endDate);
          return el;
        });
        this.setField({
          field: this.field,
          val: this.mergeSort(parsedDates, "date"),
        });
      }
    },
  },

  methods: {
    ...mapMutations("apartment", ["setField", "addOldCharge"]),

    addCharge() {
      if (this.$refs.form.validate()) {
        // Loop all and see if there is similar, set boolean false if there is.
        let boolean = true;

        const selectedDate = moment(this.charge.endDate).endOf("month").format("YYYY-MM-DD");

        this.currentApartment[this.field].forEach((el) => {
          if (
            moment(moment(el.endDate).format("YYYY-MM-DD")).isSame(selectedDate) &&
            String(el.condominiumLoanId) == String(this.charge.condominiumLoanId)
          )
            boolean = false;
        });

        if (boolean) {
          let currentCharge = { ...this.charge };
          currentCharge.endDate = selectedDate;
          this.addOldCharge({
            type: this.field,
            charge: currentCharge,
          });
          this.showPopup("Rahoitusvastike lisätty vanhoihin", "success");
        } else {
          this.showPopup(
            "Et voi lisätä uutta rahoitusvastiketta samalla nimellä ja päivämäärällä",
            "error"
          );
        }
      }
    },

    deleteItem(item, index) {
      const charge = this.getLoanByCondominiumLoanId(item);

      if (Object.keys(charge).length > 0) {
        let answer = confirm(
          `Haluatko poistaa vastikkeen ${charge.name}, päättyen: ${this.formatDate(item.endDate)}`
        );

        if (answer) {
          let currentCharges = [...this.currentApartment[this.field]];
          currentCharges.splice(index, 1);
          this.setField({ field: this.field, val: currentCharges });
        }
      } else {
        let currentCharges = [...this.currentApartment[this.field]];
        currentCharges.splice(index, 1);
        this.setField({ field: this.field, val: currentCharges });
      }
    },

    getLoanByCondominiumLoanId(item) {
      const idx = this.condominiumLoans.findIndex(
        (el) => String(el._id) == String(item.condominiumLoanId)
      );

      if (idx != -1) {
        return this.condominiumLoans[idx];
      } else {
        return {};
      }
    },

    setFieldsByCurrentLoan(condominiumLoanId) {
      if (condominiumLoanId && this.currentApartment && this.currentApartment.currentLoans) {
        const idx = this.currentApartment.currentLoans.findIndex(
          (el) => String(el.condominiumLoanId) === String(condominiumLoanId)
        );

        if (idx != -1) {
          const loan = this.currentApartment.currentLoans[idx];
          this.charge.amount = loan.amount;
        } else {
          this.charge.amount = null;
        }
      }
    },

    formatHandling(text) {
      if (text === "funding") return "Rahastoitu";
      if (text == "income recognition") return "Tuloutettu";
      else return "";
    },
  },
};
</script>

<style scoped>
.card-oldlist {
  height: 500px;
  overflow-y: scroll;
}
</style>
